@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;700;800&display=swap');

body {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 100dvh;
  margin: 0;
}

#root {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;
}
